import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Body1AD from './designD/body-1-a';

export interface Body1AProps extends PartialAbstractedTextProps {}

const Body1A: React.FC<Body1AProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Body1AProps>>({
        [Design.DesignD]: Body1AD,
    });

    return <Component {...props} />;
};

export default Body1A;
