import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { BaseSelectableComponentProps } from '@type/theme';
import { ComponentType, ReactNode } from 'react';
import DropdownA from './designA/dropdown';
import DropdownB from './designB/dropdown';
import DropdownC from './designC/dropdown';
import DropdownD from './designD/dropdown';

export interface DropdownProps<T> extends Omit<BoxProps, 'onChange'>, BaseSelectableComponentProps<T> {
    placeholder?: string;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    isInvalid?: boolean;

    /** When non-text items can be selected. */
    renderItem?: <P extends T>(props: P) => ReactNode;

    /** The number of items exposed when the dropdown is opened. */
    exposedItemCount?: number;

    triggerText?: string;
}

const Dropdown = <T,>({ items, ...props }: DropdownProps<T>) => {
    const Component = useDesignComponents<ComponentType<DropdownProps<T>>>({
        [Design.DesignA]: DropdownA,
        [Design.DesignB]: DropdownB,
        [Design.DesignC]: DropdownC,
        [Design.DesignD]: DropdownD,
    });

    const isDisabled = props.isDisabled || props.isReadOnly;

    return <Component items={isDisabled ? [] : items} pointerEvents={isDisabled ? 'none' : undefined} {...props} />;
};

export default Dropdown;
