import { useDesignComponents } from '@hooks/use-design-components';

import { Box } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import Icon, { IconProps } from '../icon';

export interface ExpandIconProps extends Omit<IconProps, 'children'> {
    isOpen?: boolean;
}

/** This component is not in Figma, but is often used in various components. */
const ExpandIcon: React.FC<ExpandIconProps> = ({ isOpen, ...props }) => {
    const { theme } = useHarmony();

    const iconProps = useDesignComponents<IconProps>({
        [Design.DesignD]: { color: theme.gray[600], size: 20 },
    });

    return (
        <Box transform={isOpen ? 'rotate(180deg)' : ''} display="flex" w="fit-content" h="fit-content" transition="transform 0.2s">
            <Icon {...iconProps} {...props}>
                expand_more
            </Icon>
        </Box>
    );
};

export default ExpandIcon;
