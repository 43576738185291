import { Body1AProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Body1AD: React.FC<Body1AProps> = (props) => {
    return (
        <AbstractedText fontSize="16px" weight="thin" lineHeight="24px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default Body1AD;
