import { Box, Button, Flex, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import ExpandIcon from '@designs/primitives/common/expand-icon';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import safeWindow from '@services/safe-window.service';
import ThemeUtils from '@utils/theme.utils';
import { useRef } from 'react';
import { DropdownProps } from '..';

const DropdownB = <T,>({
    items,
    selectedItem,
    onChange,
    placeholder,
    isDisabled,
    isReadOnly,
    isInvalid,
    renderItem,
    exposedItemCount = 4.5,
    triggerText,
    ...props
}: DropdownProps<T>) => {
    const { theme } = useHarmony();
    const disclosure = useDisclosure();

    const itemsRef = useRef<HTMLDivElement>(null);

    const triggerHeight = '44px';
    const itemsMaxHeight = (() => {
        if (!safeWindow || !itemsRef.current) {
            return '0px';
        }

        return `${(itemsRef.current.scrollHeight / items.length) * Math.min(exposedItemCount, items.length)}px`;
    })();
    const containerMaxHeight = disclosure.isOpen ? itemsMaxHeight : '0px';

    const triggerLabelColor = isDisabled || isReadOnly ? theme.gray[800] : !!selectedItem ? theme.text.body1 : theme.text.placeholder;
    const triggerBackgroundColor = isDisabled || isReadOnly ? theme.background.gray50 : theme.background.white;

    return (
        /* Wrapper */
        <Box position="relative" height={triggerHeight} {...props}>
            {/* Trigger */}
            <Button
                onClick={disclosure.onToggle}
                type="button"
                variant="unstyled"
                w="100%"
                h={triggerHeight}
                px="16px"
                backgroundColor={triggerBackgroundColor}
                border="1px solid"
                borderColor={isInvalid ? theme.status.error : disclosure.isOpen ? theme.stroke.black : theme.gray[200]}
                borderBottomColor={disclosure.isOpen ? 'transparent' : undefined}
                borderRadius={'8px'}
                borderBottomRadius={disclosure.isOpen ? '0px' : undefined}
                transitionProperty="border-color"
                transitionDuration="0.2s"
            >
                <HStack spacing="8px">
                    <BodyText size="18px" flex={1} textAlign="left" color={triggerLabelColor}>
                        {selectedItem ? triggerText || selectedItem.label : placeholder}
                    </BodyText>
                    <ExpandIcon isOpen={disclosure.isOpen} />
                </HStack>
            </Button>

            {/* Container */}
            <Box
                position="absolute"
                top={triggerHeight}
                left={0}
                right={0}
                w="100%"
                maxH={containerMaxHeight}
                backgroundColor={theme.white}
                border="1px solid"
                borderColor={theme.stroke.black}
                borderRadius={'8px'}
                opacity={disclosure.isOpen ? 1 : 0}
                transitionProperty="max-height, opacity"
                transitionDuration="0.2s"
                zIndex={disclosure.isOpen ? ThemeUtils.zIndex.dropdown : undefined}
                overflow="hidden"
            >
                {/* Items */}
                <Box ref={itemsRef} overflowY="scroll" h={itemsMaxHeight}>
                    <VStack spacing="0" alignItems="stretch">
                        {items.map((item, index) => (
                            <Button
                                key={index}
                                onClick={() => {
                                    onChange?.(item);
                                    disclosure.onClose();
                                }}
                                type="button"
                                variant="unstyled"
                            >
                                {renderItem ? (
                                    renderItem(item.value)
                                ) : (
                                    <Flex h="40px" px="12px" alignItems="center">
                                        <BodyText size="18px" flex={1} textAlign="left" color={theme.text.body1}>{`${item.label}`}</BodyText>
                                    </Flex>
                                )}
                            </Button>
                        ))}
                    </VStack>
                </Box>
            </Box>
        </Box>
    );
};

export default DropdownB;
